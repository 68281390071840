(function ($) {
/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radiobuttons')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radiobuttons input:checked')
    .parent()
    .addClass('__checked');

/* checkboxes */
$(document).on('change', '.__checkbox input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkbox input:checked')
    .parent()
    .addClass('__checked');

/* focus styling */
$(document).ready(function() {
    $('.field_wrap.__radiobuttons input').focus(function() {
        $(this)
            .closest('label')
            .addClass('__focused');
    });
    $('.field_wrap.__radiobuttons input').blur(function() {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
    $('.field_wrap.__checkbox input').focus(function() {
        $(this)
            .closest('label')
            .addClass('__focused');
    });
    $('.field_wrap.__checkbox input').blur(function() {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
});

/* checkboxes - login/remember me */
$(document).on('change', '.woocommerce-form-login__rememberme input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.woocommerce-form-login__rememberme input:checked')
    .parent()
    .addClass('__checked');
})(jQuery);
