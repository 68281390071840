// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;
require('./modules/jquery.blockUI');

// load fonts
WebFont.load({
    google: { // add &display=swap to end of ONLY END font family, not every single one, other it doesnt load those fonts after
        families: ['Inter:400,500,600,700&display=swap']
    },
    active: function() { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true
    }
});

// Load BS
require('bootstrap');

//Load Libraries
require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('objectFitPolyfill/dist/objectFitPolyfill.min');
require('select2/dist/js/select2.full.min');
require('slick-slider/slick/slick.min');
require('jquery-zoom/jquery.zoom');

//Load Modules
require('./modules/accordion');
require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
require('./modules/localVideoBlock');
require('./modules/menu');
require('./modules/modal');
require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
require('./modules/sideMenu');
require('./modules/tabs');
require('./modules/countdown');

require('./searchAutocomplete');
require('./copyBillingInfo');

// reveal/hide basket total

(function ($) {
    if ($(window).width() > 992) {
        $('.aux-bar-basket').hover(function () {
            $('.aux-bar-basket-dropdown').fadeIn(250);
        }, function () {
            $('.aux-bar-basket-dropdown').fadeOut(250);
        })

        $('.aux-bar-basket').focusin(function () {
            $('.aux-bar-basket-dropdown').fadeIn(250);
        })

        $('.__green').focusout(function () {
            $('.aux-bar-basket-dropdown').fadeOut(250);
        })
    }
})(jQuery);

(function ($) {
    var searchBarElement = $('.searchBarElement');

    $(function () {
        if ($(window).width() < 992) {
            searchBarElement.addClass('__mobile');
        }
    });

    var mobile_reset = function mobile_reset() {
        searchBarElement.removeClass('__mobile');
    }

    // find initial browser size
    onResizeReadMoreLayout();
    // on resize reset the navigation items
    $(window).on('resize', onResizeReadMoreLayout);
    function onResizeReadMoreLayout(){
        if ($(window).width() >= 900) {
            mobile_reset();
        }
    }

    $('input.qty').on('blur', function(e) {
        const $this = $(this);
        let val = parseInt($this.val());
        let max = parseInt($this.attr('max'));

        if (Number.isInteger(max)) {
            if (val > max) {
                $this.val(max);
            }
        }

    });
})(jQuery);

/**
 * Quantity increment/decrement functions.
 */
window.incrementQuantity = function (target) {
    let qty = $(target).siblings('.qty');
    let max = parseInt(qty.attr('max'));
    let val = parseInt(qty.val());

    if (Number.isInteger(max)) {
        val = Math.min(max, val + 1);
    } else {
        val = val + 1;
    }

    qty.val(val).trigger('change');
}

window.decrementQuantity = function (target) {
    let qty = $(target).siblings('.qty');
    let val = parseInt(qty.val());
    if (val > 1) {
        qty.val(val - 1).trigger('change');
    }
}